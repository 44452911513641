ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 33px;
    &.select-container {
        width: 493px;
    }
    &.block-list {
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        margin: 0 0 20px;
        .dndPlaceholder {
            width: 100%;
            height: 30px;
            background: #000;
            margin: 2px 0;
        }
        .md-editor {
            .md-fullscreen-controls {
                display: none;
            }
            &.md-fullscreen-mode {
                .md-fullscreen-controls {
                    display: block;
                }
            }
            .md-header {
                .md-control {
                    span {
                        padding: 0 !important;
                    }
                }
            }
            .btn-sm span {
                padding: 0 !important;
            }
        }
    }
}

.container-list-item {
    background: #e9e8e8;
    padding: 0 14px 0 17px;
    margin: 14px 0 0;
    .block {
        display: none;
    }
    &.rolled-down {
        .block {
            display: block;
        }
    }
    span {
        padding: 27px 0 26px;
    }
    ul {
        margin: 17px 0 0;
        li {
            flex-grow: 2;
            margin: 0 0 0 15px;
            input {
                width: 100%;
                padding: 0;
                margin: 0;
                height: 100%;
                display: block;
                text-indent: 13px;
            }
        }
        li:first-child {
            flex-grow: 0;
            margin: 0;
        }
        &.image-upload {
            justify-content: flex-start;
            li {
                flex-grow: 0;
                margin: 0;
                &:first-child {
                    margin: 0 28px 0 0;
                    label {
                        width: 100%;
                        display: flex;
                        input {
                            width: 0;
                        }
                        .custom-btn {
                            height: auto;
                            width: auto;
                            padding: 0 20px;
                        }
                    }
                }
            }
        }
    }
    figure {
        margin: 15px 0 0;
        img {
            background: #6a6a6a;
            width: 667px;
            height: auto;
            display: block;
        }
    }
    .remove-btn-container {
        margin: 10px 0 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        div {
            display: flex;
            flex-direction: column;
            .fa {
                font-size: 15px;
                text-decoration: none;
            }
        }
    }
}