ul {
    display: flex;
    align-items: center;
    li:last-child {
        display: flex;
        margin: 0 0 0 60px;
        align-items: center;
        .input-group {
            display: flex;
            margin: 0;
            .form-control {
                display: inline-block;
                width: auto;
            }
        }
        .custom-btn {
            width: 100px;
            margin: 0 0 0 10px;
            height: 32px;
        }
    }
}