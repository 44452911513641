@mixin center-with-absolute {
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

$panel_grey: #f8f8f8;
$chat-guest-background: #565656;
$chat-operator-background: #336473;
$chat-typing-background: #949494;
$chat-background: #f2f2f2;
$chat-footer-background: #e1e1e1;