.upload-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    li {
        margin: 0 15px 0 0;
    }
    .remove-btn-container {
        color: #000;
        justify-content: flex-end;
    }
    &.is-upload-file li {
        flex-grow: 2;
        &.remove-item {
            flex-grow: 1;
            margin: 5px 0 0;
        }
    }
}

section {
    padding: 30px;
    background: #fff;
    strong {}
    div {
        display: flex;
        margin: 28px 0 0;
        height: 153px;
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        background: #6a6a6a;
        span {
            border-left: solid 5px #aeadad;
            background: #fff;
            width: 526px;
            height: 41px;
        }
    }
    .custom-btn {
        width: auto;
        padding: 5px 20px;
        font-size: 18px;
    }
    &.bg-colors {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            li {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            div {
                display: block;
                padding: 0;
                margin: 0 0 10px;
                width: 140px;
                height: 140px;
            }
        }
    }
}