.upload-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    li {
        margin: 0 15px 0 0;
    }
    .remove-btn-container {
        color: #000;
        justify-content: flex-end;
    }
    &.is-upload-file li {
        flex-grow: 2;
        &.remove-item {
            flex-grow: 1;
            margin: 5px 0 0;
        }
    }
}

div {
    position: relative;
    padding: 30px;
    strong {
        position: absolute;
        top: 30px;
        left: 30px;
    }
    .custom-btn {
        display: inline-block;
        width: auto;
        padding: 5px 20px;
        font-size: 18px;
    }
    ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        &.cropped-list {
            justify-content: flex-start;
            li {
                margin: 0 10px 0 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            label {
                margin: 10px 0 0;
            }
            .little,
            .empty {
                width: 140px;
                height: 140px;
            }
            .half-big,
            .big {
                height: 280px;
            }
            .half-big {
                width: 140px;
            }
            .big {
                width: 280px;
            }
        }
        &.bg-colors {
            div {
                padding: 0;
                margin: 0 0 10px;
                width: 140px;
                height: 140px;
            }
        }
    }
}