#popup-notification-form {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    z-index: 1;
    fieldset {
        width: 50%;
        background: #fff;
        margin: 0;
        border: none;
        border-radius: 0 0 5px 5px;
        padding: 0 0 10px;
        legend {
            background: #fff;
            padding: 10px;
            border-radius: 5px 5px 0 0;
            a {
                text-decoration: none;
                float: right;
            }
        }
        div {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 10px;
            label {
                display: flex;
                flex-flow: column nowrap;
            }
            button {
                align-self: flex-end;
            }
        }
    }
}