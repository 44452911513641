@import "../_fonts";
@import "../_variables";
#article-editor .content {
    display: flex;
    padding: 14px 25px 0;
    position: relative;
    .back-btn {
        background: #f0a112;
        border-radius: 2px;
        align-self: flex-start;
        margin: 0 14px 0 0;
        text-decoration: none;
        color: #fff;
        width: auto;
        height: 42px;
        display: flex;
        align-items: center;
        font: $open-sans-bold;
        font-size: 16px;
        padding: 0 114px 0 0;
        span {
            background: url("../img/new_article_sprite.png") -43px 0 no-repeat;
            width: 8px;
            height: 19px;
            margin: 0 14px;
        }
    }
    #preview-btn {
        position: fixed;
        top: 50%;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 0 5px 5px 0;
        background: #f0a112;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
        color: #000;
        text-decoration: none;
        font-size: 30px;
    }
    .admin-panel {
        width: 984px;
        background-color: $panel-grey;
        border-radius: 2px;
        min-height: 640px;
        padding: 28px 15px;
        margin: 0;
        h3 {
            margin: 0 0 24px;
        }
        form {
            .form-blocks {
                display: block;
                legend {
                    border: none;
                }
                .custom-btn {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    height: 100%;
                    display: block;
                    background: #565656;
                    color: #fff;
                    border: none;
                    border-radius: 3px;
                    text-align: center;
                    padding: 0 20px;
                    text-decoration: none;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                .custom-checkbox {
                    position: relative;
                    height: auto;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    input[type="radio"] {
                        visibility: hidden;
                        width: 32px;
                        height: 32px;
                        margin: 0 10px 0 0;
                    }
                    .checkbox-container {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: url("../img/new_article_sprite.png") 0 -27px no-repeat;
                        z-index: 1;
                        width: 32px;
                        height: 32px;
                        opacity: 0.5;
                    }
                    &:hover .checkbox-container {
                        opacity: 1;
                    }
                    &.checked {
                        .checkbox-container {
                            background-position: -40px -27px;
                            opacity: 1;
                        }
                    }
                }
                .remove-btn-container {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    .remove-btn {
                        background: url("../img/new_article_sprite.png") 0 0 no-repeat;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        opacity: 0.5;
                        margin: 0 0 0 5px;
                        &.black {
                            background-position: -24px 0;
                        }
                    }
                    &:hover .remove-btn {
                        opacity: 1;
                    }
                }
                .custom-upload-btn {
                    display: flex;
                    width: 100%;
                    margin: 0;
                    cursor: pointer;
                    input {
                        width: 0;
                    }
                    .button {
                        height: auto;
                        width: auto;
                        display: block;
                        background: #565656;
                        color: #fff;
                        border: none;
                        border-radius: 3px;
                        text-align: center;
                        padding: 4px 20px 0;
                    }
                    i {
                        align-self: center;
                    }
                }
                .roll-btn-container {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    cursor: pointer;
                    .roll-btn {
                        display: inline-block;
                        background: url("../img/new_article_sprite.png") 0 -101px no-repeat;
                        width: 36px;
                        height: 15px;
                        transform: rotate(180deg);
                        opacity: 0.5;
                        &.rolled-down {
                            transform: rotate(0);
                        }
                    }
                    &:hover .roll-btn {
                        opacity: 1;
                    }
                }
                &#type-input {
                    margin: 0 0 20px;
                    @import "component/type_input";
                }
                &#categories-input {
                    @import "component/categories_input";
                }
                &#titles-input {
                    @import "component/titles_input";
                }
                &#tile-upload {
                    @import "component/tile_upload";
                }
                &#background-upload {
                    @import "component/background_upload";
                }
                &#blocks-input {
                    @import "component/blocks_input";
                }
                &#tags-input {
                    @import "component/tags_editor_input";
                }
                &#publish-date {
                    @import "component/publish_date";
                }
                &#popup-message {
                    @import "component/popup_message";
                }
            }
        }
    }
}