label {
    width: 263px;
    display: flex;
    justify-content: space-between;
}

span {
    font-size: 16px;
    margin: 0 0 0 20px;
}

.custom-btn {
    display: inline-block;
    width: auto;
    padding: 5px 20px;
    margin: 0 0 0 20px;
}

.notify-input, .select-input {
    width: auto;
    display: inline-block;
    margin: 0 0 0 10px;
}