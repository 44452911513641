div {
    display: flex;
    margin: 0 0 15px;
    label {
        display: flex;
        width: 263px;
        justify-content: space-between;
        margin: 0;
    }
    ul {
        display: flex;
        align-items: center;
        margin: 0 0 0 15px;
        a {
            background: #565656;
            border-radius: 3px;
            padding: 2px 2px 2px 5px;
            color: #fff;
        }
        li {
            margin: 0 0 0 6px;
        }
    }
}