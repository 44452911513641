ul {
    display: flex;
    height: 20px;
    li {
        margin: 0 6px 0 0;
        background: #565656;
        border-radius: 3px;
        .remove-btn-container {
            color: #fff;
            height: 20px;
            padding: 0 1px 0 5px;
        }
        &:last-child {
            margin: 0;
        }
    }
    &.add-tag {
        width: 385px;
        height: 33px;
        margin: 30px 0 10px;
        li {
            border-radius: none;
            background: inherit;
            margin: 0;
            &:first-child {
                margin: 0 10px 0 0;
            }
        }
    }
}