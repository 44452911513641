@import "fonts";
@import "variables";
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font: $open-sans-regular;
}

a {
  cursor: pointer;
}

h2 {
  font: $open-sans-bold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 15px;
}

label {
  font: $open-sans-regular;
  font-size: 16px;
  margin-bottom: 15px;
}

.clickable {
  cursor: pointer;
}

.m-b-20 {
  margin-bottom: 20px;
}

.inline {
  display: inline-block;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-t-20 {
  margin-top: 20px;
}

img.thumbnail {
  max-width: 100px;
  height: auto;
  max-height: 200px;
}

#wrapper {
  min-height: 100%;
  position: relative;
}

.fa--active {
  color: #f00;
}

.header {
  a {
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }
  .btn {
    margin-top: 10px;
  }
}

.bkg {
  &--grey {
    background-color: #ddd;
  }
  &--orange {
    background-color: #f0ad4e;
  }
}

.breadcrumb {
  background: url('../img/breadcrumb-bkg.png');
  padding: 0;
  margin-bottom: 15px;
  li {
    font-size: 16px;
    padding: 11px 25px;
    background-color: #faa713;
    span,
    a {
      color: white;
    }
    &+li:before {
      content: "";
      width: 2px;
      height: 40px;
      -webkit-transform: skew(25deg);
      -moz-transform: skew(25deg);
      -o-transform: skew(25deg);
      background: #fff4c1;
      padding: 0;
      margin-top: -11px;
      margin-left: -25px;
      position: absolute;
    }
    &:last-of-type {
      padding-right: 40px;
      &:after {
        content: "";
        width: 20px;
        height: 40px;
        -webkit-transform: skew(25deg);
        -moz-transform: skew(25deg);
        -o-transform: skew(25deg);
        background: url('../img/breadcrumb-bkg.png');
        padding: 0;
        margin-top: -11px;
        margin-left: 30px;
        position: absolute;
      }
    }
  }
}

.list-header {
  font-size: 16px;
  margin-bottom: 12px;
  small {
    font-style: italic;
    color: #3c3c3c;
  }
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 7px 4px;
    //font: $open-sans-semibold;
    font-size: 16px;
    &.moveable {
      cursor: move;
    }
    a {
      margin-right: 6px;
      color: #aaaaaa;
    }
  }
}

.sidebar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    a {
      font-size: 16px;
      padding: 20px;
      display: block;
      background-color: #f0f0f0;
      color: black;
      border: 1px solid #e5e5e5;
      border-right: 0;
      border-left: 0;
    }
  }
}

//Spinner
.spinner-container {
  background-color: white;
  height: 100%;
  min-height: 100px;
  position: absolute;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  &__spinner {
    @include center-with-absolute;
    background: url('../img/spinner.gif') no-repeat center;
    width: 225px;
    //height: 200px;
    background-size: contain;
    border-radius: 50%;
  }
}

#user-info-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0 0;
  .username {
    color: #000;
    line-height: 30px;
    span {
      background: #d5d5d5;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: inline-block;
      margin: 0 10px 0 0;
      .fa {
        margin: 0 9px 0 0;
      }
    }
  }
  .btn {
    margin: 0;
    background: #565656;
    border: none;
    border-radius: 3px;
    color: #fff;
    text-shadow: none;
  }
}

/** login page **/

body.login {
  background-image: url('../img/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.login-header {
  &__links {
    padding-top: 18px;
  }
  a {
    color: white;
    font: $open-sans-regular;
    font-size: 22px;
    margin: 0 15px;
  }
}

.login-panel {
  margin-top: 100px; //@TODO: vertical align
  padding: 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  .panel-body {
    padding: 0;
  }
  .form-control {
    margin-bottom: 15px;
    background-color: #f6f6f7;
    border: 3px solid #f3f3f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font: $open-sans-regular;
    font-size: 16px;
    color: #777777;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 15px;
    height: 48px;
  }
  &__title {
    font: $open-sans-light;
    font-size: 28px;
    margin-bottom: 23px;
    margin-top: 0;
    color: #333333;
  }
  &__link {
    font: $open-sans-regular;
    font-size: 16px;
    color: #777777;
  }
  .btn {
    background-color: #2b6d2e;
    color: white;
    font: $open-sans-bold;
    font-size: 24px;
    margin: 30px 0 15px 0;
  }
  .password-forgotten {
    .modal-dialog {
      width: 60%;
    }
    .modal-body {
      min-height: 700px;
      max-height: 50vw;
      overflow: auto;
    }
    .spinner-container {
      min-height: 215px;
    }
  }
}

.chat-panel {
  margin-left: 20px;
  margin-right: 20px;
   ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }
   ::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }
   ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
  }
  .panel-body {
    background-color: $chat-background;
    height: 400px;
    overflow-y: auto;
    position: relative;
  }
  .typing-indicator {
    background-color: $chat-typing-background;
    border-radius: 0 5px 5px 5px;
    bottom: 120px;
    color: white;
    font-style: italic;
    left: 120px;
    margin: 10px 0;
    max-width: 75%;
    min-height: 30px;
    padding: 10px;
    position: absolute;
  }
  .message-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .message {
      background-color: $chat-guest-background;
      border-radius: 5px 0 5px 5px;
      color: white;
      margin: 10px 0;
      max-width: 75%;
      min-height: 30px;
      padding: 10px;
      white-space: pre-wrap;
      word-break: normal;
    }
    &.system {
      align-items: center;
      .message {
        background-color: white;
        color: grey;
      }
    }
    &.operator {
      align-items: flex-end;
      .message {
        background-color: $chat-operator-background;
        border-top-right-radius: 5px;
        border-top-left-radius: 0;
      }
    }
    .time {
      color: black;
    }
  }
  .panel .slidedown .glyphicon,
  .chat .glyphicon {
    margin-right: 5px;
  }
  .panel:not(.chat-panel) .panel-body {
    .start-panel {
      margin-top: 150px;
    }
    border-color: #ffffff;
    overflow-y: scroll;
    height: 400px;
  }
  .panel-heading {
    background-image: -webkit-linear-gradient(top, #ffffff 0, #ffffff 100%);
    color: #000000;
    border-color: #faa713;
    border-bottom-width: 5px;
    .box-content {
      display: inline-block;
      padding-right: 10px;
      padding-left: 10px;
    }
    .right {
      border-right: 1px solid #faa713;
    }
  }
  .panel-footer {
    background-color: $chat-footer-background;
    .input-group-btn {
      padding-right: 10px;
      padding-left: 10px;
      .btn-default {
        margin-top: 15px;
      }
    }
    .btn-send {
      color: #ffffff;
      background-color: #faa713;
    }
  }
  #emoji-picker {
    height: auto;
    width: auto;
    background: #f3f3f3;
    position: relative;
    z-index: 1;
    left: 0;
    bottom: 0;
    display: block;
    padding-top: 10px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 0 10px 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .textarea {
    border: none;
    height: 81px;
    padding: 10px;
    resize: none;
    width: 100%;
    overflow-y: auto;
    background: #fff;
    &:focus {
      border: none;
      outline: none;
    }
    img {
      width: 22px;
      height: auto;
    }
  }
}

.connecting-screen {
  background-color: rgba(255, 255, 255, .7);
  bottom: 0;
  left: 0;
  padding-top: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 3;
}

.panel-primary {
  border-color: #ffffff;
}

.bold {
  font-weight: bold;
}

.archived-message {
  align-items: center;
  display: flex;
  i.fa {
    font-size: 25px;
    margin-right: 8px;
  }
  textarea {
    background: #1a5277;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    padding: 10px;
    width: 95%;
    &.guest {
      background: #61636d;
    }
  }
  &--handover {
    margin: 20px 0;
    text-align: center;
  }
}

.uib-tab {
  &.directConv {
    >a,
    >a:hover,
    >a:focus {
      color: white;
      background-color: rgba(0, 0, 255, 0.6);
    }
    &.active>a,
    &.active>a:focus,
    &.active>a:hover {
      background-color: blue;
      color: white;
    }
  }
  &.channelConv {
    >a,
    >a:hover,
    >a:focus {
      background-color: rgba(250, 167, 19, 0.6);
      color: white;
    }
    &.active>a,
    &.active>a:focus,
    &.active>a:hover {
      background-color: rgb(250, 167, 19);
      color: white;
    }
  }
}

.auto-message {
  .auto-message-title {
    align-items: center;
    display: flex;
    .title {
      margin-right: auto;
    }
    >select {
      font-size: 14px;
      height: 40px;
      margin-left: 15px;
      padding: 5px;
    }
    .glyphicon.glyphicon-remove-circle {
      color: red;
      font-size: 16px;
      margin-left: 15px;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  /*> * {
    display: inline-block;
  }*/
}

.bh-panel {
  background-color: white;
  border: 10px solid $panel_grey;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}

input,
textarea,
select {
  &.invalid,
  &.ng-invalid {
    border: 2px solid red;
  }
}

.color-container {
  &.has-error {
    border: 2px solid red;
  }
  .colors {
    align-items: center;
    display: inline-flex;
    margin: 25px;
    >label {
      margin: 0 10px;
    }
  }
}

.child-image-container,
.adult-image-container {
  strong {
    display: block;
    padding: 10px 0;
  }
}

#beep {
  display: none;
}

@import "articleEditor/page";
@import "components/notification";
@import "components/popup_notification_form";