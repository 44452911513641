#notification {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: hidden;
    margin: 0 0 20px 20px;
    z-index: 1;
    div {
        position: relative;
        top: 0;
        left: -250px;
        width: auto;
        height: 60px;
        border-radius: 5px;
        background: gray;
        color: #fff;
        opacity: 0;
        margin: 3px;
        box-shadow: 0 0 3px #000;
        transition: left 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
        text-align: center;
        padding: 0 10px;
        font-size: 15px;
        font-weight: bold;
        line-height: 60px;
        &.show {
            left: 0;
            opacity: 1;
        }
    }
}