label {
    display: block;
    width: 100%;
    textarea {
        background: #fff;
        width: 100%;
        border: solid 1px #000;
        padding: 5px 13px;
        margin: 13px 0 0;
    }
}

.custom-btn {
    display: inline;
    padding: 5px 20px;
    margin: 0 0 0 10px;
}